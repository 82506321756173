import React from 'react';
import { graphql } from 'gatsby';
import LayoutIT from '../components/layoutIT';
import PersonalBlogIT from '../containers/homeIT';
import SEO from '../components/seo';



const HomePage = (props: any) => {
  const { data } = props;

  return (
    <LayoutIT>
      <SEO
        title="Galleria Sifrein Parigi"
        description={data.site.siteMetadata.description}
      />
      <PersonalBlogIT />
    </LayoutIT>
  );
};

export default HomePage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
